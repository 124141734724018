import axios from "axios";
import { BoxByID } from "./models/BoxesReq/boxes";
import { TALL_CALENDER, USER_CALENDER } from "./models/CalenderModel/CalenderModel";
import { ActionID } from "./models/CancelReq";
import { CityModel } from "./models/CityModel/CIty";
import { CONTACT_US } from "./models/ContactUs";
import { ContactsModel } from './models/ContactsModel/ContactsMOdel';
import { COMMISSIONS, DEAL_MODEL } from "./models/DealApplication";
import { DevelopmentCompany_Model } from "./models/DevolpCompanyModel";
import { EVENTS, EXPENSES_TYPE } from "./models/EventsData";
import { DESGINE_COMMENTS, FINISHING_ITEMS, FINISHING_PROGRESS, FINISHING_REQUEST, FINISHING_TRANSACTIONS, GetAllFinishingFinanceTransactionsResp, SaveContractReq, SaveFinishingCostReq, SaveFinishingDesginsReq, SaveFinishingPaymentPlanReq, UpdateFinishingCostProgressReq } from "./models/Finishing";
import { FoundersModel } from "./models/FoundersModel/Founders";
import { JOINUS_MODEL } from "./models/JOINUS_MODEL";
import { JobsModel } from './models/Jobs/Jobs';
import { LoginReq, LoginResp } from "./models/LoginRQ";
import { OwnersModel } from "./models/OwnersModel/Owners";
import { PermissionModel } from './models/Permission/Permission';
import { USER_POSTS } from "./models/PostsModel";
import { SITE_VISIT_MODEL, VISIT_SITE_LOCATIONS } from "./models/SITEVISITMODEL";
import { STATION } from "./models/STATION";
import { UserIDWithCollected, UserSalaries } from "./models/Salary_Model";
import { SECOND_MEETING_REQ } from "./models/SecondMeeting";
import { TeamModel } from './models/TeamModel/TeamModel';
import { CashBoxBalance, TRANSACTIONS, TRANSACTION_TYPES, UserDeals } from "./models/TransActions_model";
import { GetUsersWithAdmins, InviteModel, InviteModelResp, USER_Detail, UserModel } from './models/UserModel/UserModel';
import { USER_RESERVATION } from "./models/UserReservation";
import { FO_REQ, OwnersFounderStats } from "./models/Wallet";
import { GetCompanyRS } from "./models/company/GetCompanyRS";
import { saveCompanyRQ } from "./models/company/SaveCompanyRQ";
import { ProjectCompgainReq, ProjectModel } from "./models/projects/ProjectModel";
import { STATISTICS, STATISTICS_REQ, UserAmount } from "./models/statistics/indext";

export const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'X-Host': window.location.hostname,
    },
    withCredentials: true,
    credentials: 'include',
}



// // ✅ Named function
// function getPromise(): Promise<number> {
//     return Promise.resolve(5);
//   }

//   // 👇️ Unwrap promise type if necessary
//   // 👇️ type T = number
//   type T = Awaited<ReturnType<typeof getPromise>>;

//   // ✅ Named async function
//   async function getPromise2(): Promise<number> {
//     return 10;
//   }

//   // ✅ Arrow function
//   const getPromise3 = (): Promise<string> => {
//     return Promise.resolve('bobbyhadz.com');
//   };

export const GetConnectionFromServer1 = (): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        axios
            .get("/api/ping")
            .then(function (response) {
                resolve(response.data.message);

            }).catch(err => reject(err));
    });
};


export const Login = (req: LoginReq): Promise<LoginResp> => {


    var reqBodySerialized = JSON.stringify(req);


    return new Promise<LoginResp>((resolve, reject) => {
        axios
            .post("/api/login", reqBodySerialized, axiosConfig)
            .then(function (response) {
                const DesData = JSON.parse(JSON.stringify(response.data));
                resolve(DesData);
            }).catch(err => {
                reject(err)
            });
    });
};
export const LogOut = (): Promise<void> => {


    return new Promise<void>((resolve, reject) => {
        axios
            .post("/api/logout", axiosConfig)
            .then(function (response) {
                const DesData = JSON.parse(JSON.stringify(response.data));
                resolve(DesData);
            }).catch(err => {
                reject(err)
            });
    });
};


export const SaveCompany = (company: saveCompanyRQ): Promise<LoginResp> => {


    var reqBodySerialized = JSON.stringify(company);


    return new Promise<LoginResp>((resolve, reject) => {
        axios
            .post("/api/company/saveCompany", reqBodySerialized, axiosConfig)
            .then(function (response) {
                const DesData = JSON.parse(JSON.stringify(response.data));
                resolve(DesData);
            }).catch(err => {
                reject(err)
            });
    });
};

export const GetCompany = (): Promise<GetCompanyRS> => {


    // var reqBodySerialized = JSON.stringify(company);


    return new Promise<GetCompanyRS>((resolve, reject) => {
        axios
            .get("/api/company/getCompany", axiosConfig)
            .then(function (response) {
                const DesData = JSON.parse(JSON.stringify(response.data));
                resolve(DesData);
            }).catch(err => {
                reject(err)
            });
    });
};


export const getVersion = (): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        try {
            axios
                .get("/api/getVersion", axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(response.data);

                    resolve(response.data);
                }).catch(err => reject(err));
        } catch (err) {

        }
    });
};


export const getAllProjects = (): Promise<ProjectModel[]> => {
    return new Promise<ProjectModel[]>((resolve, reject) => {
        try {
            axios
                .get("/api/projects/getAllProjects", axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
};

export const SaveCity = (req: CityModel): Promise<boolean> => {
    var reqBodySerialized = JSON.stringify(req);
    return new Promise<boolean>((res, rej) => {
        try {
            axios
                .post("/api/cities/saveCity", reqBodySerialized, axiosConfig)
                .then(function (response) {
                    let data = response.data as CityModel[]
                    res(true)
                }).catch(err => {
                    res(false)
                })
        } catch (err) {
            res(false);
        }
    });
}


export const GetCitiesAPI = (): Promise<CityModel[]> => {

    // var reqBodySerialized = JSON.stringify(req);

    return new Promise<CityModel[]>((resolve, reject) => {
        try {
            axios
                .get("/api/cities/getAllCities", axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
};

export const SaveProject = (req: ProjectModel): Promise<void> => {

    var reqBodySerialized = JSON.stringify(req);

    return new Promise<void>((resolve, reject) => {
        try {
            axios
                .post("/api/projects/saveProject", reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
};

export const RegisterUser = (req: UserModel): Promise<void> => {

    var reqBodySerialized = JSON.stringify(req);

    return new Promise<void>((resolve, reject) => {
        try {
            axios
                .post("/api/registerUser", reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
};


export const GetAllUsers = (): Promise<UserModel[]> => {
    return new Promise<UserModel[]>((resolve, reject) => {
        try {
            axios
                .get("/api/users/getAllUsers", axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const GetAllUsers_online = (): Promise<USER_Detail[]> => {
    return new Promise<USER_Detail[]>((resolve, reject) => {
        try {
            axios
                .get("/api/users/getMyChilds", axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const GetAllUserDetail = (Req: GetUsersWithAdmins): Promise<USER_Detail[]> => {
    var reqBodySerialized = JSON.stringify(Req);

    return new Promise<USER_Detail[]>((resolve, reject) => {
        try {
            axios
                .post("/api/users/getUsersDetails", reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const GetUsersHasFinishingRequests = (Req: GetUsersWithAdmins): Promise<USER_Detail[]> => {
    var reqBodySerialized = JSON.stringify(Req);

    return new Promise<USER_Detail[]>((resolve, reject) => {
        try {
            axios
                .post("/api/users/getUsersHasFinishingRequests", reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const getUserById = (): Promise<UserModel> => {
    // var reqBodySerialized = JSON.stringify(req);

    return new Promise<UserModel>((resolve, reject) => {
        try {
            axios
                .get("/api/users/getUserByID", axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const GetAllPermissions = (): Promise<PermissionModel[]> => {
    // var reqBodySerialized = JSON.stringify(req);

    return new Promise<PermissionModel[]>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/perm/getAllPermissions`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const GetAllJobsAPI = (): Promise<JobsModel[]> => {
    // var reqBodySerialized = JSON.stringify(req);

    return new Promise<JobsModel[]>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/perm/getAllJobs`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const SaveUserAPI = (req: UserModel): Promise<void> => {
    var reqBodySerialized = JSON.stringify(req);

    return new Promise<void>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/users/saveUser`, reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const SaveJobAPI = (req: JobsModel): Promise<void> => {
    var reqBodySerialized = JSON.stringify(req);

    return new Promise<void>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/perm/saveJob`, reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const GetAllContactsAPI = (): Promise<ContactsModel[]> => {
    // var reqBodySerialized = JSON.stringify(req);

    return new Promise<ContactsModel[]>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/contacts/getAllUserContacts`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const SaveContactAPI = (req: ContactsModel): Promise<void> => {
    var reqBodySerialized = JSON.stringify(req);

    return new Promise<void>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/contacts/saveContact`, reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const DeleteContactAPI = (req: { CONTACT_ID: number }): Promise<void> => {
    var reqBodySerialized = JSON.stringify(req);

    return new Promise<void>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/contacts/deleteContactByID`, reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const GetAllChildsAPI = (): Promise<TeamModel[]> => {

    return new Promise<TeamModel[]>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/users/getAllUserChilds`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}


export const InvitePerson = (req: InviteModel): Promise<InviteModelResp> => {

    return new Promise<InviteModelResp>((resolve, reject) => {

        var reqBodySerialized = JSON.stringify(req);

        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/users/sendInvitation`, reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const GetRankedUser = (): Promise<UserModel[]> => {

    return new Promise<UserModel[]>((resolve, reject) => {


        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/users/getRankedUsers`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const SaveUserFreeTime = (req: USER_CALENDER): Promise<void> => {
    var reqBodySerialized = JSON.stringify(req);
    return new Promise<void>((resolve, reject) => {
        try {

            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/calendar/saveUserFreeTime`, reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const getUserCalender = (): Promise<USER_CALENDER[]> => {
    return new Promise<USER_CALENDER[]>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/calendar/getMyCalendar`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const getLeaderCalender = (): Promise<USER_CALENDER[]> => {
    return new Promise<USER_CALENDER[]>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/calendar/getMyLeaderCalendar`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
//Personal & Leader
export const getAllCalender = (): Promise<TALL_CALENDER> => {
    return new Promise<TALL_CALENDER>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/calendar/getFreeTimeCalendar`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const get_confirmed_appointment_details = (CONTACT_ID: number): Promise<USER_RESERVATION> => {

    let req_serialization = JSON.stringify({ CONTACT_ID })

    return new Promise<USER_RESERVATION>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/contacts/getLastAppointementTime`, req_serialization, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const send_join_us_request = (Req: JOINUS_MODEL): Promise<any> => {

    let req_serialization = JSON.stringify(Req)

    return new Promise<any>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/careers/applyForJob`, req_serialization, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

type TStream = {
    error: any,
    data: any
}

export const get_clients_stream = (url: string): any => {
    const fetchData = async () => {
        try {
            const response = await fetch(url);
            if (!response.ok || !response.body) {
                throw response.statusText;
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { value, done } = await reader.read();
                if (done) {
                    return
                    break;
                }

                const decodedChunk = decoder.decode(value, { stream: true })
            }
        } catch (error) {

            // Handle other errors
        }
    };

    fetchData();
}


export const get_All_Jobs_Applications = (): Promise<JOINUS_MODEL[]> => {
    return new Promise<JOINUS_MODEL[]>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/careers/getAllCareerApplies`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const sendContactUsForm = (req: CONTACT_US): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        let req_serialization = JSON.stringify(req)
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/contactUs`, req_serialization, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const projectCamp_save = (req: ProjectCompgainReq): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        let req_serialization = JSON.stringify(req)
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/projects/saveProjectCompgain`, req_serialization, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const projectCamp_GetAll = (): Promise<ProjectModel[]> => {
    return new Promise<any>((resolve, reject) => {
        // let req_serialization = JSON.stringify(req)
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/projects/getAllProjectsCompagin`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const GetAllSecondMeetingRequests = (): Promise<SECOND_MEETING_REQ[]> => {
    return new Promise<any>((resolve, reject) => {
        // let req_serialization = JSON.stringify(req)
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/contacts/getAllSecondMeetingReq`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}



export const getProfileDataById = ({ USERID }: { USERID: number }): Promise<UserModel> => {
    let req_serialization = JSON.stringify({ USERID })
    return new Promise<UserModel>((res, rej) => {
        try {
            axios.post('api/profile/getUserByID', req_serialization, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}

export const SEND_POST = (req: USER_POSTS): Promise<any> => {
    let req_serialization = JSON.stringify(req)
    return new Promise<any>((res, rej) => {
        try {
            axios.post(`/api/posts/submitPost`, req_serialization, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}

export const GET_ALL_STATIONS = (): Promise<STATION[]> => {
    return new Promise<any>((res, rej) => {
        try {
            axios.get(`/api/stations/getAllStations`, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}

export const GET_ALL_DEALS = (): Promise<DEAL_MODEL[]> => {
    return new Promise<any>((res, rej) => {
        try {
            axios.get(`/api/deals/getAllDeals`, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}
export const GET_ALL_DEALS_HAS_COMMISSION = (): Promise<DEAL_MODEL[]> => {
    return new Promise<any>((res, rej) => {
        try {
            axios.get(`/api/deals/getAllDealsHasCommission`, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}

export const GET_ALL_SITES = (): Promise<VISIT_SITE_LOCATIONS[]> => {
    return new Promise<VISIT_SITE_LOCATIONS[]>((res, rej) => {
        try {
            axios.get(`/api/site/getAllSiteVisitLocations`, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}
export const GET_ALL_TRANSPORT_REQUEST = (): Promise<SITE_VISIT_MODEL[]> => {
    return new Promise<SITE_VISIT_MODEL[]>((res, rej) => {
        try {
            axios.get(`/api/site/getAllSiteVisitRequests`, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}

export const SAVE_SITE_VISIT_STATUS = (req: SITE_VISIT_MODEL): Promise<any> => {
    return new Promise<any>((res, rej) => {
        let req_serialization = JSON.stringify(req)
        try {
            axios.post(`/api/site/saveVisisSiteReq`, req_serialization, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}

export const SAVE_COMMISSION = (req: COMMISSIONS): Promise<any> => {
    return new Promise<any>((res, rej) => {
        let req_serialization = JSON.stringify(req)
        try {
            axios.post(`/api/deals/saveCommision`, req_serialization, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}
export const AccountantActions = (req: DEAL_MODEL): Promise<DEAL_MODEL> => {
    return new Promise<DEAL_MODEL>((res, rej) => {
        let req_serialization = JSON.stringify(req)
        try {
            axios.post(`/api/deals/actionDeal`, req_serialization, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}

export const EditDeal = (req: DEAL_MODEL): Promise<any> => {
    return new Promise<any>((res, rej) => {
        let req_serialization = JSON.stringify(req)
        try {
            axios.post(`/api/deals/editDeal`, req_serialization, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}


export const getStatistics = (req: STATISTICS_REQ): Promise<STATISTICS> => {
    return new Promise<STATISTICS>((res, rej) => {
        let req_serialization = JSON.stringify(req)
        try {
            axios.post(`/api/stats/getAllUserStatistics`, req_serialization, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}
export const getTeamStatistics = (req: STATISTICS_REQ): Promise<STATISTICS> => {
    return new Promise<STATISTICS>((res, rej) => {
        let req_serialization = JSON.stringify(req)
        try {
            axios.post(`/api/stats/getUserStatistics`, req_serialization, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}


export const GET_FOUNDERS_OWNERS_WALLET = (req: FO_REQ): Promise<OwnersFounderStats> => {
    let req_serialization = JSON.stringify(req)
    return new Promise<OwnersFounderStats>((res, rej) => {
        try {
            axios.post(`/api/stats/getAllOwnersFoundersWallet`, req_serialization, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}



export const GetAllFoundersAPI = (): Promise<FoundersModel[]> => {
    // var reqBodySerialized = JSON.stringify(req);
    return new Promise<FoundersModel[]>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/founders/getAllFounders`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const SaveFounderAPI = (req: FoundersModel): Promise<void> => {
    var reqBodySerialized = JSON.stringify(req);
    return new Promise<void>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/founders/saveFounder`, reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const GetAllOwnersAPI = (): Promise<OwnersModel[]> => {
    // var reqBodySerialized = JSON.stringify(req);
    return new Promise<OwnersModel[]>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/founders/getAllOwners`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const SaveOwnerAPI = (req: OwnersModel): Promise<void> => {
    var reqBodySerialized = JSON.stringify(req);

    return new Promise<void>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/founders/saveOwner`, reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const trans_types = (): Promise<TRANSACTION_TYPES[]> => {
    // var reqBodySerialized = JSON.stringify(req);

    return new Promise<TRANSACTION_TYPES[]>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/fin/getAllTransactionTypes`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const trans_type_save = (req: TRANSACTION_TYPES): Promise<void> => {
    var reqBodySerialized = JSON.stringify(req);

    return new Promise<void>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/fin/saveTransactionType`, reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const trans_fin_save = (req: TRANSACTIONS): Promise<void> => {
    var reqBodySerialized = JSON.stringify(req);

    return new Promise<void>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/fin/saveFinanceTransaction`, reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const trans_boxes_balance = (): Promise<any> => {
    // var reqBodySerialized = JSON.stringify(req);

    return new Promise<any>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/fin/getBoxesBalance`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const trans_all = (): Promise<TRANSACTIONS[]> => {
    // var reqBodySerialized = JSON.stringify(req);

    return new Promise<TRANSACTIONS[]>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .get(`/api/fin/getAllFinanceTransactions`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => { });
        } catch (err) {
            { }
        }
    });
}
export const trans_deals = (USERID: number, withoutCollected: boolean = false): Promise<UserDeals> => {
    var reqBodySerialized = JSON.stringify({ USERID, WITHOUT_COLLECTED: withoutCollected });

    return new Promise<UserDeals>((resolve, reject) => {
        try {
            axios
                // .get(`${BASEURL}/perm/getAllPermissions`, axiosConfig)
                .post(`/api/deal/getUserDeals`, reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => { });
        } catch (err) {
            { }
        }
    });
}


export const GET_ALL_DevelopmentCompanies = (): Promise<DevelopmentCompany_Model[]> => {
    return new Promise<DevelopmentCompany_Model[]>((res, rej) => {
        try {

            axios.get(`/api/devComp/getAllDevelopmentCompanies`, axiosConfig).then(response => {
                let data = response.data as DevelopmentCompany_Model[]

                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}
export const Save_Develop_Company = (req: DevelopmentCompany_Model): Promise<boolean> => {
    var reqBodySerialized = JSON.stringify(req);
    return new Promise<boolean>((res, rej) => {
        try {

            axios.post(`/api/devComp/saveDevelopmentCompany`, reqBodySerialized, axiosConfig).then(response => {
                let data = response.data as DevelopmentCompany_Model[]

                res(true)
            }).catch(err => {
                res(false)
            })
        } catch (err) {
            res(false);
        }
    });
}



export const Get_Safe_Balance = (): Promise<CashBoxBalance> => {
    // var reqBodySerialized = JSON.stringify(req);
    return new Promise<CashBoxBalance>((res, rej) => {
        try {

            axios.get(`/api/fin/getBoxesBalance`, axiosConfig).then(response => {
                let data = response.data as CashBoxBalance
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}


export const GetAllBoxTransactions = (req: BoxByID): Promise<any> => {
    return new Promise<any>((res, rej) => {
        let req_serialization = JSON.stringify(req)
        try {
            axios.post(`/api/fin/getAllBoxTransactions`, req_serialization, axiosConfig).then(response => {
                let data = response.data
                res(data)

            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });
}


export const CancelDeal = (req: ActionID): Promise<boolean> => {
    return new Promise<boolean>((res, rej) => {
        let req_serialization = JSON.stringify(req)
        try {
            axios.post(`/api/deals/cancelDeal`, req_serialization, axiosConfig).then(response => {
                let data = response.data
                res(true)

            }).catch(err => {
                res(false)
            })
        } catch (err) {
            res(false);
        }
    });
}



export const GetAllUserSalaries = (): Promise<UserSalaries[]> => {
    return new Promise<UserSalaries[]>((resolve, reject) => {
        axios
            .get("/api/salaries/getAllUserSalaries", axiosConfig)
            .then(function (response) {
                resolve(response.data);
            }).catch(err => reject(err));
    });
};


export const SaveSalary = (req: UserSalaries): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        let req_serialization = JSON.stringify(req)
        axios
            .post("/api/salaries/saveUserSalary", req_serialization, axiosConfig)
            .then(function (response) {
                resolve(response.data);
            }).catch(err => reject(err));
    });
};

export const GetUserSalary = (req: UserIDWithCollected): Promise<UserSalaries[]> => {
    return new Promise<UserSalaries[]>((resolve, reject) => {
        let req_serialization = JSON.stringify(req)
        axios
            .post("/api/salaries/getUserSalaries", req_serialization, axiosConfig)
            .then(function (response) {
                resolve(response.data);
            }).catch(err => reject(err));
    });
};


// const SaveEvent = "/events/saveEvent"
// const GetAllEvents = "/events/getAllEvents"
// const GetAllExpTypes = "/events/getAllExpTypes"

export const SaveEvent = (req: EVENTS): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        let req_serialization = JSON.stringify(req)
        axios
            .post("/api/events/saveEvent", req_serialization, axiosConfig)
            .then(function (response) {
                resolve(response.data);
            }).catch(err => reject(err));
    });
};
export const GetAllEvents = (): Promise<EVENTS[]> => {
    return new Promise<EVENTS[]>((resolve, reject) => {
        axios
            .get("/api/events/getAllEvents", axiosConfig)
            .then(function (response) {
                resolve(response.data);
            }).catch(err => reject(err));
    });
};
export const GetAllExpTypes = (): Promise<EXPENSES_TYPE[]> => {
    return new Promise<EXPENSES_TYPE[]>((resolve, reject) => {
        axios
            .get("/api/events/getAllExpTypes", axiosConfig)
            .then(function (response) {
                resolve(response.data);
            }).catch(err => reject(err));
    });
};

//
export const SaveVisitExpenses = (req: SITE_VISIT_MODEL): Promise<any> => {
    return new Promise<any>((res, rej) => {
        let req_serialization = JSON.stringify(req)
        try {
            axios.post('/api/site/saveVisitExpenses', req_serialization, axiosConfig).then(response => {
                let data = response.data
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });

}
export const GetVisitExpensesByUser = ({ USERID }: { USERID: number }): Promise<SITE_VISIT_MODEL[]> => {
    return new Promise<SITE_VISIT_MODEL[]>((res, rej) => {
        let req_serialization = JSON.stringify({ USERID })
        try {
            axios.post('/api/site/getAllVisitHasExpenses', req_serialization, axiosConfig).then(response => {
                let data = response.data as SITE_VISIT_MODEL[]
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });

}
export const GetFounderAvailabeBalance = ({ USERID }: { USERID: number }): Promise<UserAmount> => {
    return new Promise<UserAmount>((res, rej) => {
        let req_serialization = JSON.stringify({ USERID })
        try {
            axios.post('/api/founders/getFounderAvailabeBalance', req_serialization, axiosConfig).then(response => {
                let data = response.data as UserAmount
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });

}
export const SaveFinishApplication = (req: FINISHING_REQUEST): Promise<UserAmount> => {
    return new Promise<UserAmount>((res, rej) => {
        let req_serialization = JSON.stringify(req)
        try {
            axios.post('/api/finishing/saveFinishingRequest', req_serialization, axiosConfig).then(response => {
                let data = response.data as UserAmount
                res(data)
            }).catch(err => {
                rej(err)
            })
        } catch (err) {
            rej(err);
        }
    });

}


export const GetAllFinishingRequest = (): Promise<FINISHING_REQUEST[]> => {
    return new Promise<FINISHING_REQUEST[]>((resolve, reject) => {
        try {
            axios
                .get(`/api/finishing/getAllFinishingRequest`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const SaveFinishingDesign = (req: SaveFinishingDesginsReq): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        let req_serialization = JSON.stringify(req)
        try {
            axios.post('/api/finishing/saveFinishingDesgins', req_serialization, axiosConfig).then(response => {
                resolve(1)
            }).catch(err => {
                reject(err)
            })
        } catch (err) {
            reject(err);
        }
    }
    )
}

export const SaveFinishingItems = (Req: FINISHING_ITEMS): Promise<void> => {
    var reqBodySerialized = JSON.stringify(Req);

    return new Promise<void>((resolve, reject) => {
        try {
            axios
                .post("/api/finishing/saveFinishingItems", reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const SaveFinishingComment = (Req: DESGINE_COMMENTS): Promise<void> => {
    var reqBodySerialized = JSON.stringify(Req);

    return new Promise<void>((resolve, reject) => {
        try {
            axios
                .post("/api/finishing/saveDesginComment", reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const AcceptFinishDesign = ({ REQUEST_ID }: { REQUEST_ID: number }): Promise<any> => {
    var reqBodySerialized = JSON.stringify({ REQUEST_ID });

    return new Promise<any>(async (resolve, reject) => {
        try {
            await axios.post("/api/finishing/acceptDesgin", reqBodySerialized, axiosConfig)
            resolve(1)
        } catch (err) {
            reject(err);
        }
    });
}
export const SaveFinishProgress = (req: FINISHING_PROGRESS): Promise<any> => {
    var reqBodySerialized = JSON.stringify(req);

    return new Promise<any>(async (resolve, reject) => {
        try {
            await axios.post("/api/finishing/saveFinishingProgress", reqBodySerialized, axiosConfig)
            resolve(1)
        } catch (err) {
            reject(err)
        }
    })
}
export const SaveFinishingCost = (Req: SaveFinishingCostReq): Promise<void> => {
    var reqBodySerialized = JSON.stringify(Req);

    return new Promise<void>((resolve, reject) => {
        try {
            axios
                .post("/api/finishing/saveFinishingCost", reqBodySerialized, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}



export const GetFinishingItems = (): Promise<FINISHING_ITEMS[]> => {
    return new Promise<FINISHING_ITEMS[]>((resolve, reject) => {
        try {
            axios
                .get(`/api/finishing/getFinishingItems`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const GetFinishRequestByID = (REQUEST_ID: number): Promise<FINISHING_REQUEST> => {
    return new Promise<FINISHING_REQUEST>((resolve, reject) => {
        let serializedData = JSON.stringify({ REQUEST_ID })
        try {
            axios
                .post(`/api/finishing/getFinishingRequestByID`, serializedData, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const savePaymentPlanForFinishing = (request: SaveFinishingPaymentPlanReq): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        let serializedData = JSON.stringify(request)
        try {
            axios
                .post(`/api/finishing/saveFinishingPaymenetPlan`, serializedData, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const SaveContractFinish = (request: SaveContractReq): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        let serializedData = JSON.stringify(request)
        try {
            axios
                .post(`/api/finishing/saveFinishingContract`, serializedData, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const UpdateProgress = (request: UpdateFinishingCostProgressReq): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        let serializedData = JSON.stringify(request)
        try {
            axios
                .post(`/api/finishing/updateCostProgress`, serializedData, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

export const SaveFinishingFinanceTransaction = (request: FINISHING_TRANSACTIONS): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        let serializedData = JSON.stringify(request)
        try {
            axios
                .post(`/api/fin/saveFinishingFinanceTransaction`, serializedData, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
export const GetAllFinishingFinanceTransactions = (): Promise<GetAllFinishingFinanceTransactionsResp> => {
    return new Promise<GetAllFinishingFinanceTransactionsResp>((resolve, reject) => {
        // let serializedData = JSON.stringify(request)
        try {
            axios
                .get(`/api/fin/getAllFinishingFinanceTransactions`, axiosConfig)
                .then(function (response) {
                    const DesData = JSON.parse(JSON.stringify(response.data));
                    resolve(DesData);
                }).catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}
