import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import ButtonAnimated, { animationType } from '../../../../components/ButtonAnimated';
import { Carousel, CarouselItem } from '../../../../components/Carousel2';
import { DropdownMenu, DropdownToggle, DropdownWord } from '../../../../components/DropDownWord';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '../../../../components/GAccordion';
import ProgressBar from '../../../../components/GProgressBar';
import { GetErrorAxios, HavePermission, PERMISSIONS } from '../../../../Globals';
import { UsersState } from '../../../../redux/slices/UserSlice';
import { AppDispatch } from '../../../../redux/store';
import { GetFinishRequestByID, UpdateProgress } from '../../../../Service/Apis';
import { FINISHING_COST, FINISHING_COST_DETAILS, FINISHING_REQUEST, UpdateFinishingCostProgressReq } from '../../../../Service/models/Finishing';
import FinishContract from '../FinishContract';
import FinishingProgress from '../FinishProgress';
import ImgDiscussion from '../ImagesDiscution/indext';
import PaymentPlane from '../PaymentPlans';
import UploadImgs from './ImageUploadComp';
import Costestimation from './ImageUploadComp/Costestimation';
import { EgButton } from '../../../Shared/Shared';
import GModal from '../../../../components/GModal';
import ClientFinance from '../clientFinance';


export default function Request() {
    const UsersData = useSelector(UsersState);
    const [searchParams] = useSearchParams();
    const { state } = useLocation()
    // Example to get a specific query parameter
    const idValue = searchParams.get('id'); // Replace 'paramName' with your actual query parameter name
    const [finishApp, setFinishApp] = useState<FINISHING_REQUEST>(state);
    const [errr, setErro] = useState<boolean>(false)
    const [animate, setAnimate] = useState<animationType>("IDLE")

    const dispatch = useDispatch<AppDispatch>()


    useEffect(() => {
        GetFinishRequestByID(parseInt(idValue!)).then(res => {
            let finish: FINISHING_REQUEST = res as FINISHING_REQUEST
            setFinishApp(finish)
        }).catch(err => {
            setErro(true)
            GetErrorAxios(err, dispatch)
        })

    }, [])


    // make accoridion items array
    type AccordionItem = {
        idx: number,
        header: string,
        panel: JSX.Element
    }
    const AccordionItems: AccordionItem[] = [
        {
            idx: -2,
            header: "الماليات",
            panel: <>
                <ClientFinance FinishRequest={finishApp} />
            </>
        },
        ...(finishApp?.HAS_CONTRACT ? [
            {
                idx: -1,
                header: "الإنجاز",
                panel: <>
                    <StatisticsForTotalUnit FinishRequest={finishApp} />
                </>
            },
        ] : []),
        ...(UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_DESGIN, UsersData.currentUser.PERMISSIONS) ? [
            {
                idx: 0,
                header: "إضافه تصميم",
                panel: <>
                    {!finishApp?.DESGIN_ACCEPTED && (
                        <UploadImgs id={parseInt(idValue!)} />
                    )}
                </>
            }
        ] : []),
        ...(finishApp?.FINISHING_DESIGINS && finishApp?.FINISHING_DESIGINS.length > 0 ? [
            {
                idx: 1,
                header: "التصاميم",
                panel: <>
                    <ImgDiscussion Accepted={finishApp.DESGIN_ACCEPTED ?? false} RequestID={finishApp.ID!} Designs={finishApp.FINISHING_DESIGINS} AppCreator={finishApp.USER_ID!} />
                </>
            }
        ] : []),
        ... ((finishApp?.FINISHING_COST && finishApp?.FINISHING_COST.length > 0) || (UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_COST_ESTIM, UsersData.currentUser.PERMISSIONS))) ? [
            {
                idx: 2,
                header: "التكلفه التقديريه",
                panel: <>
                    {((finishApp?.FINISHING_COST && finishApp?.FINISHING_COST.length > 0) || (UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_COST_ESTIM, UsersData.currentUser.PERMISSIONS))) && (
                        <Costestimation id={parseInt(idValue!)} costs={finishApp.FINISHING_COST ?? []} totCost={finishApp.TOTAL_COST??"0"} />
                    )}
                </>
            }] : [],
        ... (finishApp?.FINISHING_PAYMENT_PLAN || (UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_PAYMENT_PLAN, UsersData.currentUser.PERMISSIONS))) ? [
            {
                idx: 3,
                header: "خطه السداد",
                panel: <>
                    {(finishApp?.FINISHING_PAYMENT_PLAN || (UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_PAYMENT_PLAN, UsersData.currentUser.PERMISSIONS))) && (
                        <PaymentPlane finishApp={finishApp} />
                    )}
                </>
            }] : [],
        ...(finishApp?.HAS_CONTRACT || (UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_CONTRACT, UsersData.currentUser.PERMISSIONS)) ? [
            {
                idx: 4,
                header: "العقد",
                panel: <>
                    <FinishContract finishRequest={finishApp} />
                </>
            },
        ] : []),
        {
            idx: 5,
            header: "تحديثات التشطيب",
            panel: <FinishingProgress FINISHING_REQ_ID={finishApp?.ID!} />
        }
    ]



    return (
        <div className='bg-slate-50 w-full  rounded-xl shadow-md overflow-hidden p-3'>
            {finishApp ? (
                <>

                    <Accordion>
                        {AccordionItems.map((item, index) => (
                            <AccordionItem key={item.idx} index={item.idx}>
                                <AccordionHeader index={item.idx}>{item.header}</AccordionHeader>
                                <AccordionPanel index={item.idx}>
                                    {item.panel}
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </>
            ) : <>
                <div>
                    {errr && (
                        <h1 className='text-red-500 text-center'>Plz contact call-center</h1>
                    )}
                    {!errr && (
                        <h1 className='text-green-500 text-center'>Getting Data</h1>
                    )}
                </div>
            </>}

        </div>
    )
}



type TPercentages = {
    description: string;
    percentage: number;
}

const DummyPercentages: TPercentages[] = [
    { description: 'الكهرباء', percentage: 50 },
    { description: 'الدهان', percentage: 70 },
    { description: 'النجاره', percentage: 30 },
    { description: 'السباكه', percentage: 90 },
    { description: 'التكييف', percentage: 90 },
    { description: 'التشطيب الخارجي', percentage: 10 },
    { description: 'التشطيب الداخلي', percentage: 43 },
    { description: 'التشطيب العام', percentage: 57 },

]

type TStatisticsProps = {
    FinishRequest: FINISHING_REQUEST;
}

const StatisticsForTotalUnit = (Props: TStatisticsProps) => {

    const { FinishRequest } = Props;
    const UsersData = useSelector(UsersState);

    const [Totalpercentage, setTotalpercentage] = useState(0);
    const [percentages, setPercentages] = useState<TPercentages[]>(DummyPercentages);
    const [finishRequestState, setFinishRequest] = useState<FINISHING_REQUEST>(FinishRequest);

    const [UpdateProgress, setUpdateProgress] = useState<boolean>(false);

    const { PROGRESS, FINISHING_PROGRESS, FINISHING_COST } = finishRequestState



    useEffect(() => {
        setFinishRequest(FinishRequest)
    }, [FinishRequest]);

    return <div className='bg-white shadow p-5 rounded-xl flex flex-col '>
        <div className='w-full'>
            <ProgressBar
                percentage={PROGRESS ?? 0}
                label="نسبه الإنجاز الكلي"
                showCompletedPercentage={true}
                duration={2000}
            />
        </div>
        <div className="w-full flex flex-wrap gap-10 mt-10">
            {(FINISHING_COST || []).map((item, index) => (
                <div className='w-1/6'>
                    <ProgressBar
                        percentage={item.PROGRESS ?? 0}
                        label={item.ITEM_NAME}
                        showCompletedPercentage={true}
                        duration={2000}
                        isSubItem={true}
                    />
                    <div className='flex flex-row-reverse'>
                        <DropdownWord>
                            <DropdownToggle>البنود</DropdownToggle>
                            <DropdownMenu>
                                {(item.FINISHING_COST_DETAILS || []).map((item2, index) => (
                                    <div className='flex items-center justify-start gap-2 my-2'>
                                        <div className={`${item2.IS_DONE ? "i-material-symbols-check-circle-outline text-xl text-green-400" : " i-material-symbols-cancel-outline-rounded text-xl text-red-400"}`}>

                                        </div>
                                        <div className="text-right">{item2.ITEM_DETAIL_NAME ?? "لايوحد"}</div>
                                    </div>
                                ))}
                            </DropdownMenu>
                        </DropdownWord>
                    </div>
                </div>
            ))}
        </div>
        {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.UPDATE_COST_PROGRESS, UsersData.currentUser.PERMISSIONS) && (
            <div className='w-fit self-end'>
                <EgButton HandleClick={() => {
                    setUpdateProgress(true)
                }}>
                    <p className='font-bold'>تحديث الإنجازات</p>
                </EgButton>
            </div>
        )}
        <div className='h-52 bg-transparent'>

        </div>
        <GModal OpenModal={UpdateProgress} onCloseModal={() => {
            setUpdateProgress(false)
        }}>
            <div className='w-full h-full'>
                <ProgressUpdate finishItems={FINISHING_COST ?? []} />
            </div>
        </GModal>

    </div>
}

type TProgressUpdate = {
    finishItems: FINISHING_COST[]
}

const ProgressUpdate = (Props: TProgressUpdate) => {

    const { finishItems } = Props

    const [finishDetail, setFinishDetails] = useState<FINISHING_COST[]>(finishItems)
    const [updateAnimation, setUpdateAnimation] = useState<animationType>("IDLE")

    const dispatch = useDispatch<AppDispatch>()


    const handleUpdateProgress = () => {

        let Req: UpdateFinishingCostProgressReq = { REQUEST_ID: finishDetail[0].FINISHING_REQ_ID, FINISHING_COST_DETAILS: [] }


        setUpdateAnimation("LOADING")

        let AllDetails = finishDetail.reduce((acc, item) => {
            return [...acc, ...item.FINISHING_COST_DETAILS!]
        }, [] as FINISHING_COST_DETAILS[])

        Req.FINISHING_COST_DETAILS = AllDetails

        UpdateProgress(Req).then(res => {
            setUpdateAnimation("SUCCESS")
            setTimeout(() => {
                window.location.reload()
            }, 5000);
        }).catch(err => {
            setUpdateAnimation("ERROR")
            GetErrorAxios(err, dispatch)
        })


    }

    useEffect(() => {
        console.log(finishItems)
        setFinishDetails(finishItems)
    }, [finishItems])

    return <div className='w-full h-full'>
        <h1>تحديث البنود</h1>
        {finishDetail.length > 0 ?
            <div className='flex flex-col'>
                <div className="w-full flex flex-wrap gap-10 mt-10">
                    {(finishDetail || []).map((item, index) => (
                        <div className='flex flex-col gap-4'>
                            <div>
                                <h3>{item.ITEM_NAME}</h3>
                            </div>
                            <div className='flex flex-col gap-2'>
                                {item.FINISHING_COST_DETAILS?.map((item2, index) => (
                                    <div className='flex gap-2'>
                                        <input checked={item2.IS_DONE} type="checkbox" id="Require" name="Require" className='w-[1.5em] h-[1.5em]'
                                            onChange={(e: any) => {
                                                item2.IS_DONE = e.target.checked
                                                setFinishDetails([...finishDetail])

                                            }} />
                                        <div>{item2.ITEM_DETAIL_NAME}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='self-end '>
                    <ButtonAnimated animate={updateAnimation} onClick={() => {
                        handleUpdateProgress()

                    }}>
                        <p>تحديث البنود</p>
                    </ButtonAnimated>
                </div>
            </div>
            : <div className='w-full h-52 rounded-3xl'>
                <div className='shimmer'>

                </div>
            </div>}
    </div>
}


const TestCarousel = () => {
    return (
        <div className="w-1/2 mx-auto">
            <h1 className="text-2xl font-bold mb-4">Carousel Test</h1>

            <h2 className="text-xl font-semibold mt-8 mb-2">Default Carousel</h2>
            <Carousel>
                <CarouselItem>
                    <div className="bg-red-500 w-full h-full flex items-center justify-center text-white">Item 1</div>
                </CarouselItem>
                <CarouselItem>
                    <div className="bg-blue-500 w-full h-full flex items-center justify-center text-white">Item 2</div>
                </CarouselItem>
                <CarouselItem>
                    <div className="bg-green-500 w-full h-full flex items-center justify-center text-white">Item 3</div>
                </CarouselItem>
                <CarouselItem>
                    <div className="bg-yellow-500 w-full h-full flex items-center justify-center text-white">Item 4</div>
                </CarouselItem>
                <CarouselItem>
                    <div className="bg-purple-500 w-full h-full flex items-center justify-center text-white">Item 5</div>
                </CarouselItem>
            </Carousel>

            <h2 className="text-xl font-semibold mt-8 mb-2">Fixed Carousel (3 visible items)</h2>
            <Carousel isFixed visibleItems={3}>
                <CarouselItem>
                    <div className="bg-red-500 w-full h-full flex items-center justify-center text-white">Item 1</div>
                </CarouselItem>
                <CarouselItem>
                    <div className="bg-blue-500 w-full h-full flex items-center justify-center text-white">Item 2</div>
                </CarouselItem>
                <CarouselItem>
                    <div className="bg-green-500 w-full h-full flex items-center justify-center text-white">Item 3</div>
                </CarouselItem>
            </Carousel>

            <h2 className="text-xl font-semibold mt-8 mb-2">Infinite Carousel</h2>
            <Carousel isInfinite visibleItems={2}>
                <CarouselItem>
                    <div className="bg-red-500 w-full h-full flex items-center justify-center text-white">Item 1</div>
                </CarouselItem>
                <CarouselItem>
                    <div className="bg-blue-500 w-full h-full flex items-center justify-center text-white">Item 2</div>
                </CarouselItem>
                <CarouselItem>
                    <div className="bg-green-500 w-full h-full flex items-center justify-center text-white">Item 3</div>
                </CarouselItem>
                <CarouselItem>
                    <div className="bg-yellow-500 w-full h-full flex items-center justify-center text-white">Item 4</div>
                </CarouselItem>
            </Carousel>
        </div>
    );
};
