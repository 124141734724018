import { Modal, useTheme } from '@mui/material';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PDFLogo } from '../../../components/Icons/Icons';
import { CustomTextFieldFullHeight } from '../../../CustomComps/Customs';
import { FontBase64, FormatDuration, getDatesDiffrence, GetErrorAxios, getYearMonthDayFromDate, groupBy, isArabic, LogoimgBase64 } from '../../../Globals';
import { AddAction } from '../../../redux/slices/ErrorsSlice';
import { AppDispatch } from '../../../redux/store';
import { get_All_Jobs_Applications } from '../../../Service/Apis';
import { JOINUS_MODEL } from '../../../Service/models/JOINUS_MODEL';
import { tokens } from '../../../theme';
import { DropTextField } from '../Actions/ActionsTypesScreen/AppointMents';


type MappedObject = {
  date: string;
  applications: JOINUS_MODEL[];

}

type TCVParams = {
  height: number,
  heightAddition: number,
  BodySize: number,
  HeaderSize: number,
  LineSatart: number,
  LineEnd: number
}

let initialParams: TCVParams = {
  height: 10,
  heightAddition: 7,
  HeaderSize: 12,
  BodySize: 16,
  LineSatart: 20,
  LineEnd: 190
}

const JoinRequests = () => {

  const ImgPath = '../../../Assets/images/Hero/NileView.webp'

  const [jobsApplicatins, setJobsApplications] = useState<JOINUS_MODEL[]>([]);
  const [selectedJob, setselectedJob] = useState<JOINUS_MODEL>({} as JOINUS_MODEL);
  const [openExpModal, setOpenExpModal] = useState<boolean>(false);
  const [searchFactor, setSearchFactor] = useState<number>(0);
  const [isPrevMarkter, setisPrevMarkter] = useState<boolean>(false);
  const [groupByDates, setGroupByDates] = useState<MappedObject[]>([]);
  const [selectedDropDownIDX, setSelectedDropDownIDX] = useState<number>(-1);
  const handleOpen = () => setOpenExpModal(true)
  const handleClose = () => setOpenExpModal(false)
  const dispatch = useDispatch<AppDispatch>();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };
  const [searchVal, setSearchVal] = useState<string>("")
  const [branch, setBranch] = useState<number>(-1);
  const [joinUsData, setJoinUsData] = useState<JOINUS_MODEL[]>([]);

  const JobApplicationsRef = useRef<JOINUS_MODEL[]>([]);
  const BeforeIsMarkterCheckBox = useRef<JOINUS_MODEL[]>([]);



  const printCv = (job: JOINUS_MODEL, params: TCVParams, printPage?: boolean, docExternal?: jsPDF): number => {

    const img = LogoimgBase64

    let doc: jsPDF

    if (docExternal !== undefined) {
      doc = docExternal
    } else {
      doc = new jsPDF("p", "mm", 'a4');
    }
    // let doc = new jsPDF("p", "mm", [210, 600]);

    doc.rect(5, 5, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 10, 'S');
    doc.addImage(img, 'jpeg', 0, 0, 50, 50)

    // a4 Size 	210 x 297

    let height = params.height;
    let heightAddition = params.heightAddition;

    let HeaderSize = params.HeaderSize
    let BodySize = params.BodySize

    let LineSatart = params.LineSatart
    let LineEnd = params.LineEnd

    const AddPage = (actualHeight: number, PageHeight: number, doc: jsPDF) => {
      let pageCount: number = Math.ceil((actualHeight + 10) / (PageHeight - 297));
      if (pageCount > 1) {
        doc.addPage()
        doc.rect(5, 5, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 10, 'S');
        height = 10;
        heightAddition = 7;
        HeaderSize = 12
        BodySize = 16
      }
    }

    var Arslanfont = FontBase64;
    doc.addFileToVFS(
      "(A) Arslan Wessam A (A) Arslan Wessam A-normal.ttf",
      Arslanfont
    );
    doc.addFont(
      "(A) Arslan Wessam A (A) Arslan Wessam A-normal.ttf",
      "(A) Arslan Wessam A (A) Arslan Wessam A",
      "normal"
    );


    // let height = 10;
    // let heightAddition = 7;

    // let HeaderSize = 12
    // let BodySize = 16


    doc.setFont("(A) Arslan Wessam A (A) Arslan Wessam A");

    // تاريخ التقديم
    doc.setFontSize(HeaderSize)
    doc.text("تاريخ التقديم", 200, height * 2, { align: 'right', });

    height += heightAddition * 3
    doc.setFontSize(BodySize)
    if (!isArabic(getYearMonthDayFromDate(job.STAMP_DATE, true))) {
      doc.setFont("roboto");
      doc.text(getYearMonthDayFromDate(job.STAMP_DATE, true), 200, height, { align: 'right', });
      doc.setFont("(A) Arslan Wessam A (A) Arslan Wessam A");
    } else {
      doc.text(getYearMonthDayFromDate(job.STAMP_DATE, true), 200, height, { align: 'right', });
    }
    height += heightAddition * 4

    // الإسم
    doc.setFontSize(HeaderSize)
    doc.text("الإسم", 200, height, { align: 'right', });

    doc.setFontSize(HeaderSize)
    doc.text("السن", 100, height, { align: 'right', })

    doc.setFontSize(HeaderSize)
    doc.text("الموبايل", 50, height, { align: 'right', })



    height += heightAddition
    doc.setFontSize(BodySize)
    if (!isArabic(job.FULL_NAME)) {
      doc.setFont("roboto");
      doc.text(job.FULL_NAME, 200, height, { align: 'right', });
      doc.text(job.AGE.toString(), 100, height, { align: 'right', });
      doc.text(job.MOBILE_NO, 50, height, { align: 'right', });
      doc.setFont("(A) Arslan Wessam A (A) Arslan Wessam A");
    } else {
      doc.text(job.FULL_NAME, 200, height, { align: 'right', });
      doc.text(job.AGE.toString(), 100, height, { align: 'right', });
      doc.text(job.MOBILE_NO, 50, height, { align: 'right', });
    }
    height += heightAddition

    doc.line(LineSatart, height, LineEnd, height)
    height += heightAddition
    // // السن
    // doc.setFontSize(HeaderSize)
    // doc.text("السن", 200, height, { align: 'right', });

    // height += heightAddition
    // doc.setFontSize(BodySize)
    // if (!isArabic(job.AGE.toString())) {
    //   doc.setFont("roboto");
    //   doc.text(job.AGE.toString(), 200, height, { align: 'right', });
    //   doc.setFont("(A) Arslan Wessam A (A) Arslan Wessam A");
    // } else {
    //   doc.text(job.AGE.toString(), 200, height, { align: 'right', });
    // }

    // height += heightAddition


    // الفرع
    doc.setFontSize(HeaderSize)
    doc.text("الفرع", 200, height, { align: 'right', });



    height += heightAddition
    doc.setFontSize(BodySize)
    if (!isArabic(job.ADDRESS)) {
      doc.setFont("roboto");
      doc.text(job.STATION_NAME ?? "", 200, height, { align: 'right', });
      doc.setFont("(A) Arslan Wessam A (A) Arslan Wessam A");
    } else {
      doc.text(job.STATION_NAME ?? "", 200, height, { align: 'right', });
    }

    height += heightAddition
    doc.line(LineSatart, height, LineEnd, height)
    height += heightAddition

    // العنوان
    doc.setFontSize(HeaderSize)
    doc.text("العنوان", 200, height, { align: 'right', });



    height += heightAddition
    doc.setFontSize(BodySize)
    if (!isArabic(job.ADDRESS)) {
      doc.setFont("roboto");
      doc.text(job.ADDRESS, 200, height, { align: 'right', });
      doc.setFont("(A) Arslan Wessam A (A) Arslan Wessam A");
    } else {
      doc.text(job.ADDRESS, 200, height, { align: 'right', });
    }

    height += heightAddition
    doc.line(LineSatart, height, LineEnd, height)
    height += heightAddition
    // // موبايل
    // doc.setFontSize(HeaderSize)
    // doc.text("الموبايل", 200, height, { align: 'right', });

    // height += heightAddition
    // doc.setFontSize(BodySize)
    // if (!isArabic(job.MOBILE_NO)) {
    //   doc.setFont("roboto");
    //   doc.text(job.MOBILE_NO, 200, height, { align: 'right', });
    //   doc.setFont("(A) Arslan Wessam A (A) Arslan Wessam A");
    // } else {
    //   doc.text(job.MOBILE_NO, 200, height, { align: 'right', });
    // }
    // height += heightAddition

    // البريد الإلكتروني
    doc.setFontSize(HeaderSize)
    doc.text("البريد الإلكتروني", 200, height, { align: 'right', });

    height += heightAddition
    doc.setFont("roboto");
    doc.setFontSize(BodySize)
    if (!isArabic(job.EMAIL)) {
      doc.setFont("roboto");
      doc.text(job.EMAIL, 200, height, { align: 'right', });
      doc.setFont("(A) Arslan Wessam A (A) Arslan Wessam A");
    } else {
      doc.text(job.EMAIL, 200, height, { align: 'right', });
    }

    height += heightAddition
    doc.setFont("(A) Arslan Wessam A (A) Arslan Wessam A");
    doc.line(20, height, 180, height)
    height += heightAddition
    // الجامعه
    doc.setFontSize(HeaderSize)
    doc.text("الجامعه", 200, height, { align: 'right', });

    height += heightAddition
    doc.setFontSize(BodySize)
    if (!isArabic(job.UNIVERSTY)) {
      doc.setFont("roboto");
      doc.text(job.UNIVERSTY, 200, height, { align: 'right', });
      doc.setFont("(A) Arslan Wessam A (A) Arslan Wessam A");
    } else {
      doc.text(job.UNIVERSTY, 200, height, { align: 'right', });
    }

    height += heightAddition
    // الكليه
    doc.setFontSize(HeaderSize)
    doc.text("الكليه", 200, height, { align: 'right', });

    height += heightAddition
    doc.setFontSize(BodySize)
    if (!isArabic(job.FACULTY)) {
      doc.setFont("roboto");
      doc.text(job.FACULTY, 200, height, { align: 'right', });
      doc.setFont("(A) Arslan Wessam A (A) Arslan Wessam A");
    } else {
      doc.text(job.FACULTY, 200, height, { align: 'right', });
    }

    height += heightAddition
    doc.line(LineSatart, height, LineEnd, height)
    height += heightAddition
    // تاريخ الإلتحاق
    doc.setFontSize(HeaderSize)
    doc.text("الإلتحاق", 200, height, { align: 'right', });
    doc.text("التخرج", 100, height, { align: 'right', });

    height += heightAddition
    doc.setFontSize(BodySize)
    doc.text(getYearMonthDayFromDate(job.TIME_FROM), 200, height, { align: 'right', });
    doc.text(getYearMonthDayFromDate(job.TIME_TO), 100, height, { align: 'right', });
    height += heightAddition
    // // تاريخ التخرج
    // doc.setFontSize(HeaderSize)
    // doc.text("التخرج", 200, height, { align: 'right', });

    // height += heightAddition
    // doc.setFontSize(BodySize)
    // doc.text(getYearMonthDayFromDate(job.TIME_TO), 200, height, { align: 'right', });

    height += heightAddition
    doc.line(LineSatart, height, LineEnd, height)
    height += heightAddition
    // الخبرات
    doc.setFontSize(HeaderSize)
    doc.text("الخبرات", 200, height, { align: 'right', });
    height += heightAddition * 2

    if (job.JOB_EXPERIENCE && job.JOB_EXPERIENCE.length > 0) {
      job.JOB_EXPERIENCE.map((exp, idx) => {

        let docSizes = doc.internal.pages.length
        let docInnerHeight = doc.internal.pageSize.getHeight() * docSizes



        const { Years, Days, Months, TotalDays } = getDatesDiffrence(exp.TIME_FROM, exp.TIME_TO)

        //الشركه
        doc.setFontSize(HeaderSize)
        doc.text("الشركه", 200, height, { align: 'right', });

        height += heightAddition
        AddPage(height, docInnerHeight, doc)

        doc.setFontSize(BodySize)
        if (!isArabic(exp.COMPANY_NAME)) {
          doc.setFont("roboto");
          doc.text(exp.COMPANY_NAME, 200, height, { align: 'right', });
          doc.setFont("(A) Arslan Wessam A (A) Arslan Wessam A");
        } else {
          doc.text(exp.COMPANY_NAME, 200, height, { align: 'right', });
        }

        height += heightAddition
        AddPage(height, docInnerHeight, doc)
        //الوظيفه
        doc.setFontSize(HeaderSize)
        doc.text("الوظيفه", 200, height, { align: 'right', });

        height += heightAddition
        AddPage(height, docInnerHeight, doc)
        doc.setFontSize(BodySize)
        if (!isArabic(exp.POSTION)) {
          doc.setFont("roboto");
          doc.text(exp.POSTION, 200, height, { align: 'right', });
          doc.setFont("(A) Arslan Wessam A (A) Arslan Wessam A");
        } else {
          doc.text(exp.POSTION, 200, height, { align: 'right', });
        }

        height += heightAddition
        AddPage(height, docInnerHeight, doc)
        //من
        doc.setFontSize(HeaderSize)
        doc.text("من", 200, height, { align: 'right', });
        doc.text("الى", 100, height, { align: 'right', });

        height += heightAddition
        AddPage(height, docInnerHeight, doc)
        doc.setFontSize(BodySize)
        doc.text(getYearMonthDayFromDate(exp.TIME_FROM), 200, height, { align: 'right', });
        doc.text(getYearMonthDayFromDate(exp.TIME_TO), 100, height, { align: 'right', });
        height += heightAddition
        AddPage(height, docInnerHeight, doc)
        //الى
        // doc.setFontSize(HeaderSize)
        // doc.text("الى", 200, height, { align: 'right', });

        // height += heightAddition
        // AddPage(height, docInnerHeight,doc)
        // doc.setFontSize(BodySize)
        // doc.text(getYearMonthDayFromDate(exp.TIME_TO), 200, height, { align: 'right', });
        // height += heightAddition
        // AddPage(height, docInnerHeight,doc)
        //سنين الخبره
        doc.setFontSize(HeaderSize)
        doc.text("سنين الخبره", 200, height, { align: 'right', });

        height += heightAddition
        AddPage(height, docInnerHeight, doc)
        doc.setFontSize(BodySize)
        doc.text(FormatDuration(Years, Months, Days), 200, height, { align: 'right', });

        height += heightAddition
        doc.line(LineSatart, height, LineEnd, height)
        height += heightAddition * 2
        AddPage(height, docInnerHeight, doc)

      });
    }


    if (printPage === undefined || printPage === true) {
      doc.save(job.FULL_NAME + "_" + job.STATION_NAME);
    }


    return height;
  }

  useEffect(() => {
    get_All_Jobs_Applications().then(res => {
      if (res.length > 0) {
        setJoinUsData(res)
        let ObjHolder: MappedObject[] = []
        setJobsApplications(res)
        JobApplicationsRef.current = res;
        BeforeIsMarkterCheckBox.current = res;
        let ObjectCast = groupBy(res, "STAMP_DATE", true)


        Object.entries(ObjectCast).map(([key, val]) => {
          ObjHolder.push({ date: key, applications: val })
        })

        if (ObjHolder.length > 0) {
          setGroupByDates(ObjHolder)
        }

      } else {
        dispatch(AddAction({ message: "لا يوجد تقديمات وظائف", status: "warning" }))
      }
    }).catch(err => {
      GetErrorAxios(err, dispatch)
    })
  }, []);

  useEffect(() => {
    let filteredData: JOINUS_MODEL[] = []
    if (branch === -1) filteredData = joinUsData
    if (branch !== -1) {
      filteredData = joinUsData.filter((item, idx) => item.STATION_ID === branch);
    }

    let ObjHolder: MappedObject[] = []
    setJobsApplications(filteredData)
    JobApplicationsRef.current = filteredData;
    BeforeIsMarkterCheckBox.current = filteredData;
    let ObjectCast = groupBy(filteredData, "STAMP_DATE", true)


    Object.entries(ObjectCast).map(([key, val]) => {
      ObjHolder.push({ date: key, applications: val })
    })

    if (ObjHolder.length > 0) {
      setGroupByDates(ObjHolder)
    }
  }, [branch])


  useEffect(() => {
    let ObjHolder: MappedObject[] = []

    let ObjectCast = groupBy(jobsApplicatins, "STAMP_DATE", true)


    Object.entries(ObjectCast).map(([key, val]) => {
      ObjHolder.push({ date: key, applications: val })
    })

    if (ObjHolder.length > 0) {
      setGroupByDates(ObjHolder)
    }
  }, [jobsApplicatins])



  useEffect(() => {
    if (isPrevMarkter) {
      let newArr = BeforeIsMarkterCheckBox.current.filter(job => job.WORK_AS_MARKETING === true);
      setJobsApplications(newArr);
    } else {
      setJobsApplications(BeforeIsMarkterCheckBox.current);
    }
  }, [BeforeIsMarkterCheckBox.current])

  const printAll = () => {
    const img = LogoimgBase64

    let doc = new jsPDF("p", "mm", 'a4');
    doc.rect(5, 5, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 10, 'S');
    doc.addImage(img, 'jpeg', 0, 0, 50, 50)

    // a4 Size 	210 x 297

    let height = 10;
    let heightAddition = 7;

    let HeaderSize = 12
    let BodySize = 16

    let LineSatart = 20
    let LineEnd = 190

    const AddPage = (actualHeight: number, PageHeight: number) => {
      let pageCount: number = Math.ceil((actualHeight + 10) / (PageHeight - 297));
      if (pageCount > 1) {
        doc.addPage()
        doc.rect(5, 5, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 10, 'S');
        height = 10;
        heightAddition = 7;
        HeaderSize = 12
        BodySize = 16
      }
    }


    var elattarfont = FontBase64;
    doc.addFileToVFS(
      "elattar.ttf",
      elattarfont
    );

    doc.addFont(
      "elattar.ttf",
      "elattar",
      "normal"
    );

    doc.setFont("elattar");

    let count = 0;

    for (const job of jobsApplicatins) {

      let newHeight = printCv(job, { ...initialParams, height: height }, false, doc)
      let NewPageHeight = Math.ceil(newHeight / 297) * 297
      height = NewPageHeight + 297

      let docSizes = doc.internal.pages.length
      let docInnerHeight = doc.internal.pageSize.getHeight() * docSizes

      AddPage(height, docInnerHeight)
    }
    doc.save("ALL_CVS.pdf")
  }

  return (
    <>
      <div className='h-[90vh] overflow-auto'>
        <div className='w-1/2 md:w-1/3'>
          <div>
            <div className='flex flex-wrap gap-2'>
              <h3>عدد المتقدمين</h3>
              <h3 className='font-bold'>{jobsApplicatins.length} طلب</h3>
            </div>
            <div className="flex w-[60%] justify-between items-center gap-2">
              <label className='text-base'>محدد البحث</label>
              <DropTextField initalValue={0} data={[
                { id: 0, desc: "اسم" },
                { id: 1, desc: "سن" },
              ]} onchange={e => {
                setSearchFactor(e.target.value)
              }} />
            </div>
            <div className="flex w-[60%] justify-between items-center gap-2">
              <label className='text-base'>فرع الشركه</label>
              <DropTextField initalValue={-1} data={[
                { id: -1, desc: "الكل" },
                { id: 1, desc: "المنيا" },
                { id: 2, desc: "سوهاج" },
              ]} onchange={e => {
                let val = e.target.value as number
                setBranch(val);
              }} />
            </div>

            <div>
              <CustomTextFieldFullHeight height={25} type={''} onChange={(e: any) => {
                switch (searchFactor) {
                  case 0:
                    if (JobApplicationsRef.current.length > 0) {
                      let newArr = JobApplicationsRef.current.filter(job => job.FULL_NAME.includes(e.target.value))
                      BeforeIsMarkterCheckBox.current = newArr;
                      setJobsApplications(newArr);
                    }
                    break;
                  case 1:
                    if (JobApplicationsRef.current.length > 0) {
                      let newArr = JobApplicationsRef.current.filter(job => job.AGE >= parseInt(e.target.value))
                      BeforeIsMarkterCheckBox.current = newArr;
                      setJobsApplications(newArr);
                    }
                    break;
                }

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
            <div className='flex gap-3 mr-2 my-4'>
              <input type="checkbox" checked={isPrevMarkter} id="PrevExp" name="explain" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                if (e.target.checked === true) {
                  setisPrevMarkter(true);
                  let newArr = BeforeIsMarkterCheckBox.current.filter(job => job.JOB_EXPERIENCE?.length > 0);
                  setJobsApplications(newArr);
                } else {
                  setisPrevMarkter(false);
                  setJobsApplications(BeforeIsMarkterCheckBox.current);
                }
              }} />
              <label htmlFor='PrevExp' className='text-base'>يمتلك خبرات</label>
            </div>
          </div>
        </div>
        {/* {jobsApplicatins.length > 0 && jobsApplicatins.map((job, idx) => {
          return <div dir="rtl" className="flex flex-wrap gap-6 items-center justify-between bg-[#fCfCfC] shadow-md p-2 rounded-lg my-5">
            <div className='flex flex-wrap'>
              <div className='flex gap-2 w-[4em]'>
                <p className='truncate'><span className="font-bold text-md border-l border-gray-500 p-1"> {idx + 1}</span></p>
              </div>
              <div className='flex gap-2 w-[15em]'>
                <p className="text-sm">الإسم </p>
                <p className='truncate'><span className="font-bold text-md"> {job.FULL_NAME}</span></p>
              </div>
              <div className='flex gap-2  w-[10em]'>
                <p className="text-sm">السن</p>
                <p className='truncate' ><span className="font-bold text-md">{job.AGE}</span></p>
                <p><span className="font-bold text-md"> سنه </span></p>
              </div>
              <div className='flex gap-2 w-[15em]'>
                <p className="text-sm">الجامعه</p>
                <p className='truncate'><span className="font-bold text-md"> {job.UNIVERSTY}</span></p>
              </div>
              <div className='flex gap-2 w-[15em]'>
                <p className="text-sm">كليه</p>
                <p className='truncate'><span className="font-bold text-md"> {job.FACULTY}</span></p>
              </div>
              <div className='flex gap-2 w-[15em]'>
                <p className="text-sm">الإلتحاق</p>
                <p className='truncate'><span className="font-bold text-md"> {getYearMonthDayFromDate(job.TIME_FROM)}</span></p>
              </div>
              <div className='flex gap-2 w-[15em]'>
                <p className="text-sm">التخرج</p>
                <p className='truncate'><span className="font-bold text-md"> {getYearMonthDayFromDate(job.TIME_TO)}</span></p>
              </div>
              <div className='flex gap-2 w-[15em]'>
                <p className="text-sm">الموبايل</p>
                <p className='truncate'><span className="font-bold text-md"> {job.MOBILE_NO}</span></p>
              </div>
              <div className='flex gap-2 w-[20em]'>
                <p className="text-sm">الإيميل</p>
                <p className=''><span className="font-bold text-md"> {job.EMAIL}</span></p>
              </div>
            </div>
            <div className=" flex items-center gap-1 hover:cursor-pointer hover:text-[#e7c498] p-1">
              {job.JOB_EXPERIENCE && job.JOB_EXPERIENCE.length > 0 && (
                <div className='flex' onClick={() => {
                  setselectedJob(job)
                  handleOpen()
                }}>
                  <p className='text-xl font-bold'> الخبرات </p>
                  <div className="i-material-symbols-keyboard-double-arrow-left-rounded text-2xl text-[#e7c498]" ></div>
                </div>
              )
              }
            </div>
          </div>
        })
        } */}
        {groupByDates.length > 0 && groupByDates.map((item, idx) => {
          return <div dir="rtl" className="flex w-full flex-wrap gap-4 items-center justify-between bg-[#f0f0f0] shadow-md p-2 rounded-lg my-5 relative" >
            <div className='flex flex-col w-full'>
              <div className='flex gap-4 flex-wrap w-full hover:cursor-pointer' onClick={() => {
                setSelectedDropDownIDX(prev => prev === idx ? -1 : idx)
              }}>
                <div className='flex gap-3 w-[25em]'>
                  <p className="text-xl"> التاريخ </p>
                  <p className='truncate'><span className="font-bold text-xl">{getYearMonthDayFromDate(new Date(item.date), true)}</span></p>
                </div>
                <div className='flex gap-2 w-[15em]'>
                  <p className="text-xl"> عدد المتقدمين </p>
                  <p className='truncate text-xl'><span className="font-bold text-xl"> {item.applications.length}</span></p>
                </div>
              </div>
              <div className='w-10 h-10 hover:cursor-pointer' onClick={() => {
                item.applications.map((cv, idx) => {
                  printCv(cv, initialParams);
                })
              }}>
                <PDFLogo />
              </div>
              {selectedDropDownIDX === idx && item.applications.map((job, idx) => {
                return <div dir="rtl" className="flex flex-wrap gap-6 items-center justify-between bg-[#fCfCfC] shadow-md p-2 rounded-lg my-2">
                  <div className='flex flex-wrap gap-3'>
                    <div className='flex gap-2 w-[4em]'>
                      <p className='truncate'><span className="font-bold text-md border-l border-gray-500 p-1"> {idx + 1}</span></p>
                    </div>
                    <div className='flex gap-2 w-[15em]'>
                      <p className="text-sm">الإسم </p>
                      <p className='truncate'><span className="font-bold text-md"> {job.FULL_NAME}</span></p>
                    </div>
                    <div className='flex gap-2'>
                      <p className="text-sm">السن</p>
                      <p className='truncate' ><span className="font-bold text-md">{job.AGE}</span></p>
                      <p><span className="font-bold text-md"> سنه </span></p>
                    </div>
                    <div className='flex gap-2  w-[15em]'>
                      <p className="text-sm">العنوان</p>
                      <p className='truncate' ><span className="font-bold text-md">{job.ADDRESS}</span></p>
                    </div>
                    <div className='flex gap-2 w-[12em]'>
                      <p className="text-sm">الجامعه</p>
                      <p className='truncate'><span className="font-bold text-md"> {job.UNIVERSTY}</span></p>
                    </div>
                    <div className='flex gap-2 w-[15em]'>
                      <p className="text-sm">كليه</p>
                      <p className='truncate'><span className="font-bold text-md"> {job.FACULTY}</span></p>
                    </div>
                    <div className='flex gap-2 w-[9em]'>
                      <p className="text-sm">الإلتحاق</p>
                      <p className='truncate'><span className="font-bold text-md"> {getYearMonthDayFromDate(job.TIME_FROM)}</span></p>
                    </div>
                    <div className='flex gap-2 w-[9em]'>
                      <p className="text-sm">التخرج</p>
                      <p className='truncate'><span className="font-bold text-md"> {getYearMonthDayFromDate(job.TIME_TO)}</span></p>
                    </div>
                    <div className='flex gap-2'>
                      <p className="text-sm">الموبايل</p>
                      <p className='truncate'><span className="font-bold text-md"> {job.MOBILE_NO}</span></p>
                    </div>
                    <div className='flex gap-2 w-[20em]'>
                      <p className="text-sm">الإيميل</p>
                      <p className=''><span className="font-bold text-md"> {job.EMAIL}</span></p>
                    </div>
                    <div className='flex gap-2 w-[20em]'>
                      <p className="text-sm">الفرع</p>
                      <p className=''><span className="font-bold text-md"> {job.STATION_NAME}</span></p>
                    </div>
                  </div>
                  <div className=" flex items-center gap-1 hover:cursor-pointer hover:text-[#e7c498] p-1">
                    {job.JOB_EXPERIENCE && job.JOB_EXPERIENCE.length > 0 && (
                      <div className='flex' onClick={() => {
                        setselectedJob(job)
                        handleOpen()
                      }}>
                        <p className='text-xl font-bold'> الخبرات </p>
                        <div className="i-material-symbols-keyboard-double-arrow-left-rounded text-2xl text-[#e7c498]" ></div>
                      </div>
                    )
                    }
                  </div>
                  <div>
                    <div className='w-10 h-10 hover:cursor-pointer' onClick={() => {
                      printCv(job, initialParams);
                    }}>
                      <PDFLogo />
                    </div>
                  </div>
                </div>
              })}


              {idx === selectedDropDownIDX && (
                <>
                  <div className='absolute hover:cursor-pointer top-1 left-2 i-material-symbols-keyboard-double-arrow-up-rounded text-blue-800 text-3xl' onClick={() => {
                    setSelectedDropDownIDX(prev => prev === idx ? -1 : idx)
                  }}>
                  </div>
                </>
              )}
              {idx != selectedDropDownIDX && (
                <>
                  <div className='absolute hover:cursor-pointer top-1 left-2 i-material-symbols-keyboard-double-arrow-down-rounded text-blue-800 text-3xl' onClick={() => {
                    setSelectedDropDownIDX(prev => prev === idx ? -1 : idx)
                  }}>
                  </div>
                </>
              )}

            </div>
          </div>
        })}
        <Modal
          open={openExpModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="absolute top-0 left-0 w-full h-full bg-gray-200 bg-opacity-70 backdrop-blur" onClick={() => {
            handleClose()
          }} >
            <div className="w-[60%] absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-1/2 bg-white p-5 rounded-lg">
              <div>
                {selectedJob.JOB_EXPERIENCE && selectedJob.JOB_EXPERIENCE.length > 0 && selectedJob.JOB_EXPERIENCE.map(exp => {
                  const { Years, Days, Months, TotalDays } = getDatesDiffrence(exp.TIME_FROM, exp.TIME_TO)

                  return <div className='flex flex-wrap gap-6 items-center justify-between bg-[#fCfCfC] shadow-md p-2 rounded-lg my-5'>
                    <div className='flex gap-2 w-[20em]'>
                      <h2>إسم الشركه</h2>
                      <h2> <span className="font-bold text-md">{exp.COMPANY_NAME}</span></h2>
                    </div>
                    <div className='flex gap-2 w-[20em]'>
                      <h2>الوظيفه</h2>
                      <h2> <span className="font-bold text-md">{exp.POSTION}</span></h2>
                    </div>
                    <div className='flex gap-2 w-[20em]'>
                      <h2>من</h2>
                      <h2> <span className="font-bold text-md">{getYearMonthDayFromDate(exp.TIME_FROM)}</span></h2>
                    </div>
                    <div className='flex gap-2 w-[20em]'>
                      <h2>الى</h2>
                      <h2> <span className="font-bold text-md">{getYearMonthDayFromDate(exp.TIME_TO)}</span></h2>
                    </div>
                    <div className='flex gap-2 w-[20em]'>
                      <h2>سنين الخبره</h2>
                      <h2> <span className="font-bold text-md">{FormatDuration(Years, Months, Days)}</span></h2>
                    </div>
                  </div>
                })}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default JoinRequests