import React, { useEffect, useState } from 'react'
import { Logo, THETEAM } from '../../../components/Icons/Icons'
import { Box, Button, Stack, useTheme, Typography, Avatar, Menu, MenuItem, Divider } from '@mui/material'
import { EGButton, EGNavButton, EGNavButtonRegister, NavBarText } from '../../../CustomComps/Customs'
import { tokens } from '../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { langDirState, languageDirection } from '../../../redux/slices/Lang';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { UserModel } from '../../../Service/models/UserModel/UserModel';
import { LogOutThunk } from '../../../redux/slices/CheckConnection';
import { SetCurrentUserReducer, UsersState } from '../../../redux/slices/UserSlice';
import { CookiesData, getCookies } from '../../../Globals';

function Header({ user, openDrawerFunc }: { user?: UserModel, openDrawerFunc: (flag: boolean) => void }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch<AppDispatch>();
    const langDirectionState = useSelector(langDirState);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [drawerState, setDrawerState] = useState<boolean>(true);

    const UsersData = useSelector(UsersState);

    useEffect(() => {
        let user = getCookies(CookiesData.User);

        if (user) {
            let userOBJ = JSON.parse(user) as UserModel;
            dispatch(SetCurrentUserReducer(userOBJ));
        } else {
            dispatch(SetCurrentUserReducer({} as UserModel))
        }
    }, [])

    const handleClose = () => {
        setAnchorEl(null);
    };

    const SIGNOUT = () => {
        setAnchorEl(null);
        dispatch(LogOutThunk()).then(res => {
            dispatch(SetCurrentUserReducer({} as UserModel))
        });
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            {UsersData.currentUser.IS_VERIFIED === false && (
                <div className='mx-24 h-full items-center flex flex-row justify-center'>
                    <h2 className='text-lg text-[#FF590B] m-5 text-center'>
                        برجاء تأكيد الحساب من البريد الإلكتروني
                    </h2>
                </div>
            )
            }

            <div className='w-full h-[60px] bg-[#f6f6f6]  fixed z-[1000] shadow-lg'>
                <div className=' md:hidden  absolute right-10 top-0 h-[60px] w-[60px]'>
                    <div className='p-5'>
                        <div className='flex flex-col justify-center items-center gap-2 hover:cursor-pointer' onClick={() => {
                            // setDrawerState(prev => !prev);
                            // openDrawerFunc(!drawerState);
                        }}>
                            <Box>
                                {UsersData.currentUser?.EMAIL && (
                                    <div className='' >
                                        <button onClick={handleClick}>
                                            <Avatar alt="Remy Sharp" src={UsersData.currentUser.PROFILE_PIC_URL} className="hover:cursor-pointer" />
                                        </button>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem onClick={() => {
                                                navigate('/dashboard/welcome')
                                            }}>لوحه التحكم</MenuItem>
                                            <MenuItem onClick={() => {

                                                navigate('/dashboard/EditUser')
                                            }}>الملف الشخصي</MenuItem>
                                            <Divider light />
                                            <MenuItem onClick={() => {
                                                window.location.href = 'https://academy.the-team.co/';
                                            }}> اكاديمه بناء </MenuItem>
                                            <MenuItem onClick={SIGNOUT}> تسجيل الخروج </MenuItem>
                                        </Menu>
                                    </div>
                                )}
                                {!UsersData.currentUser.EMAIL && (
                                    <div className='' >
                                        <button onClick={handleClick}>
                                            <Avatar alt="Remy Sharp" src={UsersData.currentUser.PROFILE_PIC_URL} className="hover:cursor-pointer" />
                                        </button>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem onClick={() => {

                                                navigate('/Login')
                                            }}>تسجيل الدخول</MenuItem>
                                            <Divider light />
                                            <MenuItem onClick={() => {
                                                navigate('/register')
                                            }}> مستخدم جديد </MenuItem>
                                            <Divider light />
                                            <MenuItem onClick={() => {
                                                navigate('/join-us')
                                            }}>إنضم إلينا</MenuItem>
                                        </Menu>
                                    </div>
                                    // <div>

                                    //     <EGNavButtonRegister onClick={() => {
                                    //         navigate('/register')
                                    //     }}>
                                    //         <NavBarText>{t('SignUp')}</NavBarText>
                                    //     </EGNavButtonRegister>


                                    //     <EGNavButton onClick={() => {
                                    //         navigate('/Login')
                                    //     }}>
                                    //         <h3 className='font-bold text-sm' >{t('SignIn')}</h3>
                                    //     </EGNavButton>
                                    // </div>
                                )}
                            </Box>
                        </div>
                    </div>
                </div>
                <div className='mx-24 h-full flex flex-row justify-around md:justify-between'>
                    <div className='w-2/5  flex-row justify-between items-center hidden md:flex'>
                        <Link to={'/'} >
                            <Box>
                                <EGNavButton>
                                    <NavBarText>الرئيسيه</NavBarText>
                                </EGNavButton>
                            </Box>
                        </Link>
                        {/* <Box>
                            <EGNavButton>
                                <NavBarText>{t('Execution')}</NavBarText>
                            </EGNavButton>
                        </Box> */}
                        <Box>
                            <Link to={'/Events'}>
                                <EGNavButton>
                                    {/* <NavBarText>{t('Services')}</NavBarText> */}
                                    <NavBarText>فعاليات</NavBarText>
                                </EGNavButton>
                            </Link>
                        </Box>
                        <Box>
                            <Link to={'/Agents'} >
                                <EGNavButton>
                                    {/* <NavBarText>{t('WhoWeAre')}</NavBarText> */}
                                    <NavBarText>الوكلاء</NavBarText>
                                </EGNavButton>
                            </Link>
                        </Box>
                        <Box>
                            <Link to={'/ContactUs'}>
                                <EGNavButton>
                                    <NavBarText>{t('ContactUs')}</NavBarText>
                                </EGNavButton>
                            </Link>
                        </Box>
                        <Box>
                            <Link to={"/join-us"}>
                                <EGNavButton>
                                    {/* <NavBarText>{t('finish')}</NavBarText> */}
                                    <NavBarText>إنضم لنا</NavBarText>
                                </EGNavButton>
                            </Link>
                        </Box>
                    </div>
                    <div className='w-2/5 flex justify-center'>
                        <Box style={{ width: '76px', height: '60px', padding: 5, }}>
                            <THETEAM />
                        </Box>
                    </div>
                    <div className='w-1/5  flex-row items-center justify-end hidden md:flex'>
                        {/* <Box>
                            <EGNavButton onClick={() => {
                                if (langDirectionState === 'ltr') {
                                    dispatch(languageDirection('rtl'))
                                } else {

                                    dispatch(languageDirection('ltr'))
                                }
                            }}>
                                <NavBarText>{t('language')}</NavBarText>
                            </EGNavButton>
                        </Box> */}
                        <div className='flex flex-row justify-between md:justify-around items-center w-3/4 '>
                            <Box>
                                {!user?.EMAIL && (
                                    <EGNavButton onClick={() => {
                                        navigate('/Login')
                                    }}>
                                        <NavBarText className='' >{t('SignIn')}</NavBarText>
                                        {/* <h3 className='font-bold text-sm'>{t('SignIn')}</h3> */}
                                    </EGNavButton>
                                )}
                            </Box>
                            {UsersData.currentUser && (

                                <Box>
                                    {UsersData.currentUser?.EMAIL && (
                                        <div className='' >
                                            <button onClick={handleClick}>
                                                <Avatar alt="Remy Sharp" src={UsersData.currentUser.PROFILE_PIC_URL} className="hover:cursor-pointer" />
                                            </button>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                <MenuItem onClick={() => {
                                                    navigate('/dashboard/welcome')
                                                }}>لوحه التحكم</MenuItem>
                                                <MenuItem onClick={() => {

                                                    navigate('/dashboard/EditUser')
                                                }}>الملف الشخصي</MenuItem>
                                                <MenuItem onClick={() => {
                                                    window.location.href = 'https://academy.the-team.co/';
                                                }}> اكاديمه بناء </MenuItem>
                                                <Divider light />
                                                <MenuItem onClick={SIGNOUT}> تسجيل الخروج </MenuItem>
                                            </Menu>
                                        </div>
                                    )}
                                    {!UsersData.currentUser?.EMAIL && (
                                        <EGNavButtonRegister onClick={() => {
                                            navigate('/register')
                                        }}>
                                            <NavBarText >{t('SignUp')}</NavBarText>
                                        </EGNavButtonRegister>
                                    )}

                                </Box>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Header